import React from "react"

import "./src/styles/global.css";
import "./static/assets/css/bootstrap.min.css";
import "./static/assets/css/material-design-iconic-font.css";
import "./static/assets/css/simple-line-icons.css";
import "./static/assets/css/slick.css";
import "./static/assets/css/animate.min.css";
import "./static/assets/css/jquery-ui.min.css";
import "./static/assets/scss/style.css";
import "./static/assets/scss/custom.css";

import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import {AuthProvider} from "./src/context/auth-context";
import {ThemeProvider} from "@mui/material";
import { createTheme } from '@mui/material/styles';

export const wrapPageElement = ({ element }) => {
    const theme = createTheme({
        palette: {
            primary: {
                light: '#b1494f',
                main: '#9e1c24',
                dark: '#6e1319',
                contrastText: '#fff',
            },
            secondary: {
                light: '#82c96b',
                main: '#62bb46',
                dark: '#458331',
                contrastText: '#000',
            },
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <AuthProvider rendering={'browser'}>{element}</AuthProvider>
            </LocalizationProvider>
        </ThemeProvider>
    )
}
