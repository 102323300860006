import React from "react";
import {navigate} from "@reach/router";
import {getCartDeals} from "../services/loopback";
import {useAuth} from "../context/auth-context";

const formatNumber = (num, type = "Ft") => {
    if (!isNaN(parseInt(num))) {
        if (type === '%') {
            return `${Math.round(num * 100)} %`;
        } else {
            return `${Math.round(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${type}`;
        }
    }
    return '';
}

const sizes = ["thumbnail", "small", "medium", "large"];
const getStrapiImage = (images, size=null, alt="") => {
    let image = null;
    if (Array.isArray(images)) {
        if (!images?.[0]) {
            return null;
        }
        image = images[0];
    } else {
        image = images;
    }
    if (!image) {
        return null;
    }
    let url = image.url;
    let orientation = (image.width === image.height ? "o-square" : image.width > image.height ? "o-landscape" : "o-portrait");
    if (size && sizes.includes(size)) {
        let sizeImage = null;
        const index = sizes.findIndex(item => item === size);
        for (let i= index; i < sizes.length; i++) {
            if (!sizeImage && image.formats?.[sizes[index]]) {
                sizeImage = image.formats?.[sizes[index]];
            }
        }
        if (sizeImage) {
            url = sizeImage.url;
            orientation = (sizeImage.width === sizeImage.height ? "o-square" : sizeImage.width > sizeImage.height ? "o-landscape" : "o-portrait");
        }
    }
    if (alt) {
        return <img src={`${process.env.STRAPI_API_URL}${url}`} className={`o-set ${orientation}`}
                    alt={alt ?? 'Medibon.hu'}/>;
    } else {
        return `${process.env.STRAPI_API_URL}${url}`;
    }
}

const isBrowser = () => {
    try {
        return typeof window !== "undefined";
        // const {rendering} = useAuth();
        // return rendering === 'browser';
    } catch (err) {
    }
    return false;
}

const setStorage = (container, name, value) => {
    if (isBrowser()) {
        if (typeof value !== 'string') {
            value = JSON.stringify(value);
        }
        window[container].setItem(name, value);
    }
}

const getStorage = (container, name, defaultValue) => {
    let value;
    if (isBrowser()) {
        value = window[container].getItem(name);
        if (typeof value === 'string') {
            let _value = null;
            try {
                _value = JSON.parse(value);
            } catch (e) {
                // do nothing
            }
            if (_value !== null) {
                value = _value;
            }
        }
        if (!value) {
            value = defaultValue;
        }
    } else {
        value = defaultValue;
    }
    return value;
}

const deleteStorage = (container, name) => {
    if (isBrowser()) {
        window[container].removeItem(name);
    }
}

const navigating = (url, isReplace = false) => {
    const isDev = !!process.env.DEVELOPMENT;
    if (isDev) {
        window.location.href = url;
    } else {
        navigate(url, {replace: isReplace}).catch(err => console.log(err));
    }
}

const setCartData = (cartData, settingPrice, settingCartItems) => {
    let _price = 0;
    let _cartItems = [];
    const dealIds = cartData.map(item => item.dealId);
    getCartDeals(dealIds).then(response => {
        if (response) {
            response.map(deal => {
                const cartItem = cartData.find(item => item.dealId === deal.id);
                _price += ((cartItem.buy ?? 0) + (cartItem.gift ?? 0)) * (deal.paidPrice ?? 0);
                _cartItems.push({...cartItem, deal: deal});
            });
            if (settingPrice) {
                settingPrice(_price);
            }
        }
        if (settingCartItems) {
            settingCartItems(_cartItems);
        }
    });
}

export {formatNumber, getStrapiImage, getStorage, setStorage, deleteStorage, isBrowser, navigating, setCartData};
