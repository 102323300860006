import React, { useState, useContext, useEffect } from "react"
import { navigate } from "@reach/router"
import axios from "axios"
import handleError from "../common/handle-error";
import {deleteStorage, getStorage, setStorage} from "../common/functions";
const AuthContext = React.createContext({});

export const AuthProvider = ({ ...props }) => {
  const [inited, setInited] = useState(false);
  const [me, setMe] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const loopbackUrl = process.env.LOOPBACK_API_URL;

  const rendering = props?.rendering ?? 'browser';

  const token = rendering === 'browser' ? (window.sessionStorage.getItem("token") ?? window.localStorage.getItem("token") ?? '') : '';

  const store = (data, remember) => {
    const container = (remember ? 'localStorage' : 'sessionStorage');
    setStorage(container, 'user', JSON.stringify(data.user ?? {}));
    if (data.token) {
      setStorage(container, 'token', data.token ?? "");
    }
  }

  const getMe = async (token) => {
    // console.log('getMe', token);
    try {
      const response = await axios.get(`${loopbackUrl}/me`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      if (response.data.id) {
        store({user: response.data});
        setAuthenticated(true);
        setMe({ ...response.data, token });
      }
      setInited(true);
    } catch (err) {
      handleError(err);
      setInited(true);
    }
  }

  const register = async (data, fn) => {
    let result = { success: false, message: "" }
    try {
      const response = await axios.post(`${loopbackUrl}/users/signup`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      if (response.data) {
        result = response.data;
      }
    } catch (err) {
      handleError(err);
    }
    if (!result.success) {
      console.error("result", result)
    }
    fn(result)
    return result
  }

  const updateProfile = async (data, fn) => {
    let result = { success: false, message: "" }
    try {
      const response = await axios.patch(`${loopbackUrl}/users/${data.id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data) {
        result = response.data;
      }
    } catch (err) {
      handleError(err);
    }
    if (!result.success) {
      console.error("result", result)
    }
    fn(result)
    return result
  }

  const login = async (email, password, rememberMe, fn) => {
    let result = { success: false, message: "" }
    try {
      const response = await axios.post(`${loopbackUrl}/users/login`, {
        email,
        password,
      });
      if (response.data?.user && response.data?.token) {
        store(response.data, rememberMe);
        setAuthenticated(true);
        // navigating("/private")
        result.success = true;
      }
    } catch (err) {
      handleError(err);
    }
    fn(result)
    return result
  }

  const socialLogin = async (data, rememberMe, fn) => {
    let result = { success: false, message: "" }
    try {
      const response = await axios.post(`${loopbackUrl}/users/social-login`, data);
      if (response.data?.user && response.data?.token) {
        store(response.data, rememberMe);
        setAuthenticated(true);
        // navigating("/private")
        result.success = true;
      } else {
        result.message = response.data?.message ?? 'Hiba történt!';
      }
    } catch (err) {
      handleError(err);
    }
    fn(result)
    return result
  }

  const forgotten = async (email, fn) => {
    let result = { success: false, message: "" }
    try {
      const response = await axios.post(`${loopbackUrl}/users/forgotten`, {
        email,
      });
      if (response.data) {
        result = response.data;
      }
    } catch (err) {
      handleError(err);
    }
    fn(result)
    return result
  }

  const hashValid = async (id, hash, fn) => {
    let result = { success: false, message: "" }
    try {
      const response = await axios.post(`${loopbackUrl}/users/hash`, {
        id,
        hash
      });
      if (response.data) {
        result = response.data;
      }
    } catch (err) {
      handleError(err);
    }
    fn(result)
    return result
  }

  const changePassword = async (id, hash, password, fn) => {
    let result = { success: false, message: "" }
    try {
      const response = await axios.post(`${loopbackUrl}/users/change-password`, {
        id,
        hash,
        password,
      });
      if (response.data) {
        result = response.data;
      }
    } catch (err) {
      handleError(err);
    }
    fn(result)
    return result
  }

  const confirmCancel = async (data, fn) => {
    let result = { success: false, message: "" }
    try {
      const response = await axios.post(`${loopbackUrl}/users/confirm-cancel/${data.id}`, {},{
        headers: { Authorization: `Bearer ${token}` },
      })
      if (response.data) {
        result = response.data;
      }
    } catch (err) {
      handleError(err);
    }
    fn(result)
    return result
  }

  const cancelProfile = async (data, fn) => {
    let result = { success: false, message: "" }
    try {
      const response = await axios.delete(`${loopbackUrl}/users/${data.id}/${data.hash}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      if (response.data) {
        result = response.data;
      }
    } catch (err) {
      handleError(err);
    }
    fn(result)
    return result
  }

  const confirmRegistration = async (data, fn) => {
    let result = { success: false, message: "" }
    try {
      const response = await axios.get(`${loopbackUrl}/users/confirm/${data.id}/${data.hash}`)
      if (response.data) {
        result = response.data;
        store(response.data);
        setAuthenticated(true);
      }
    } catch (err) {
      handleError(err);
    }
    fn(result)
    return result
  }

  const logout = () => {
    setAuthenticated(false);
    setMe(null);
    deleteStorage('sessionStorage', 'user');
    deleteStorage('sessionStorage', 'token');
    deleteStorage('localStorage', 'user');
    deleteStorage('localStorage', 'token');
    // navigating("/private")
  }

  useEffect(() => {
    let token;
    token = getStorage('sessionStorage', 'token', '');
    if (!token) {
      token = getStorage('localStorage', 'token', '');
    }
    if (token) {
      getMe(token)
    } else {
      setInited(true)
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        register,
        confirmRegistration,
        updateProfile,
        confirmCancel,
        cancelProfile,
        login,
        socialLogin,
        logout,
        forgotten,
        changePassword,
        hashValid,
        authenticated,
        me,
        inited,
        rendering,
      }}
      {...props}
    />
  )
}

export const useAuth = () => useContext(AuthContext)

export default AuthContext
