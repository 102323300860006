import {deleteStorage, navigating} from "./functions";
const handleError = (err) => {
    const errMsg = err.response?.data?.error?.message?.message ?? err.response?.data?.error?.message ?? err.message ?? "AuthError";
    if (errMsg.indexOf('jwt expire') !== -1) {
        deleteStorage('sessionStorage', 'user');
        deleteStorage('sessionStorage', 'token');
        deleteStorage('localStorage', 'user');
        deleteStorage('localStorage', 'token');

        console.error(errMsg + ' Logged out!');
        navigating('/login');
    } else {
        console.error(errMsg);
    }
    // return errMsg;
}

export default handleError;
